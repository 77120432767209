import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Wrapper from '../components/base/Wrapper';
import App from '../components/layout/App';
import Cta from '../components/base/Cta';

import { fontSize } from '../constants/styles';

import Pattern from '../images/logo-pattern.svg';

import {
  fontFamily,
  color,
  mqUp,
  mqDown,
  header,
  spacing,
} from '../constants/styles';

const TitleStyle = css`
  text-transform: uppercase;
  line-height: 1.25;
  font-size: ${fontSize.XL};
  ${mqUp('tablet')} {
    font-size: 5rem;
  }
`;

const TitleLight = styled.div`
  font-family: ${fontFamily.secondaryLight};
  background: -webkit-linear-gradient(${color.clr1}, ${color.clr6});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TitleBold = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: 120%;
`;

const NotFoundPage = props => {
  return (
    <App>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          text-align: center;
          background: url(${Pattern}) top right no-repeat;
          ${mqDown('mainNav')} {
            margin: -${header.heightSmall};
            padding: 10 0 10rem;
          }
          ${mqUp('mainNav')} {
            margin-top: -${header.heightBase};
            padding: 10rem 0 10rem;
          }
        `}
      >
        <Wrapper>
          <div css={TitleStyle}>
            <TitleLight>Allo houston</TitleLight>
            <TitleBold>On a eu un problème</TitleBold>
          </div>

          <div
            css={css`
              margin-top: ${spacing.XL};
            `}
          >
            <Cta to="/">Retour à l'accueil</Cta>
          </div>
        </Wrapper>
      </div>
    </App>
  );
};
export default NotFoundPage;
